// Bring in Celeratis styles
@import '~design-system/dist/main.css';
@import '~design-system/dist/helpers';

// App Styles
@import 'icons';

@include bp($bp-large) {
  --padding: var(--padding--large);
}

.button--link {
  box-shadow: none !important;
}
