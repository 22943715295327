@import '~design-system/dist/helpers';

.container {
  height: 100vh;

  @include bp($bp-xlarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 72px;
    background: #f2fcff; // unique color for sign in page.
  }
}

.wrapper {
  width: 100%;
  height: 100%;

  @include bp($bp-small) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp($bp-large) {
    grid-template-columns: .75fr 1fr;
  }

  @include bp($bp-xlarge) {
    max-width: 1196px;
    max-height: 90%;
    box-shadow: var(--box-shadow3);
    border-radius: var(--border-radius--large);
  }
}

.content {
  --horizontal-padding: 24px;

  padding: 0 var(--horizontal-padding) 32px;
  background: white;

  @include bp($bp-medium) {
    --horizontal-padding: 64px;
  }

  @include bp($bp-large) {
    --horizontal-padding: 80px;
  }

  @include bp($bp-xlarge) {
    --horizontal-padding: 120px;
    border-top-left-radius: var(--border-radius--large);
    border-bottom-left-radius: var(--border-radius--large);
  }
}

.background {
  display: none;
  position: relative;
  background-color: #cce9f8; // unique color for sign in page.

  @include bp($bp-small) {
    display: block;
  }

  @include bp($bp-xlarge) {
    border-top-right-radius: var(--border-radius--large);
    border-bottom-right-radius: var(--border-radius--large);
  }
}

.foregroundImage, .backgroundImage {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;

  @include bp($bp-xlarge) {
    border-top-right-radius: var(--border-radius--large);
    border-bottom-right-radius: var(--border-radius--large);
  }
}

.backgroundText {
  max-width: 30ch;
  padding: 175px 24px 24px;
  margin: auto;
  font-size: var(--h3-size);
  font-weight: 700;
  text-align: center;
  color: white;
  position: relative;
}

.signInErrorAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 8px 24px;
  border: 1px solid var(--c-error);
  border-radius: var(--border-radius);
  background: var(--c-error-background);
  color: var(--c-error);
  text-align: center;
  font-size: var(--font-size-medium);
  font-weight: 700;
}
