@import '~design-system/dist/helpers';

.pauseContainer {
  @include bp($bp-xlarge) {
    margin-top: 42px;
    grid-column: 9 / span 4;
  }
}

.alertContainer {
  display: grid;
  grid-template-columns: 36px minmax(0, 1fr);
  column-gap: 12px;
}

.pauseStatement {
  margin-top: 8px;
  font-size: 12px;
  color: var(--c-grey600);

  @include bp($bp-small) {
    margin-top: 12px;
  }
}

.radioGroup :global {
  animation-duration: 300ms;
  animation-iteration-count: forwards;
  animation-name: fadeIn;
}