.parent-portal-logo {
  --width: 150px;
  --height: 55px;
}

.icon-email-sent {
  --width: 68px;
  --height: 68px;
  --color: var(--c-grey300);
}

.icon-globe {
  --color: var(--c-link);
}

.icon-drawer {
  width: 56px;
  height: 36px;
  fill: var(--c-grey300);
}