@import '~design-system/dist/helpers';

.hero {
  --background: url(../../../images/shaped-background.svg);
  --foreground: url(../../../images/family.svg);
  --background-size: auto 100%;
  --background-position: bottom right;
  --foreground-position: calc(100% - 40px) calc(100% - 12px);
  --foreground-size: auto;
  --padding: 64px 16px;

  padding: var(--padding);
  margin-bottom: 36px;
  background-image: var(--background);
  background-repeat: no-repeat;
  background-position: var(--background-position);
  background-size: var(--background-size);

  @include bp($bp-small) {
    --padding: 64px 40px 32px;
  }

  @include bp($bp-medium) {
    // set the double background here.
    background-image: var(--foreground), var(--background);
    background-position: var(--foreground-position), var(--background-position);
    background-size: var(--foreground-size), var(--background-size);
  }

  @include bp($bp-large) {
    --background-position: top right;
    --foreground-position: calc(100% - 42px) 100%;
    --background-size: auto 275px;
  }

  @include bp($bp-large) {
    --background-size: cover;
    --foreground-position: calc(100% - 42px) calc(100% + 8px);

    border-bottom: 20px solid transparent;
    background-clip: border-box, padding-box;
  }
}

.heroText {
  --margin-bottom: 8px;
  --display: none;

  display: var(--display);
  margin-bottom: var(--margin-bottom);

  @include bp($bp-small) {
    --display: block;
  }

  @include bp($bp-medium) {
    --margin-bottom: 16px;
    width: 75%;
  }
}

.content {
  padding: 0 var(--padding) var(--padding);

  @include bp($bp-small) {
    --padding: 40px;
  }

  @include bp($bp-xlarge) {
    max-width: 1136px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 40px;
    padding: 0;
    margin: auto;
  }
}

.tableContent {
  @include bp($bp-xlarge) {
    grid-column: 1 / span 8;
    grid-row: 1;
  }
}

.tableContentFullWidth {
  @include bp($bp-xlarge) {
    grid-column-end: span 12;
  }
}

.studentSelectContainer {
  min-width: 256px;

  @include bp($bp-medium) {
    max-width: 512px;
  }
}

.studentSelect {
  @include bp($bp-large) {
    margin-bottom: 24px;
  }
}

.pastDaysLabel {
  margin: 40px 0 32px;
  font-size: 22px;

  @include bp($bp-xlarge) {
    margin-top: 0;
  }
}
